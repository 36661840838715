import React, { useState } from 'react';
import AlertIcon from '../../assets/icons/AlertIcon.svg';
import {
  Container,
  InnerContainer,
  Content,
  ImageWrapper,
  ButtonWrapper,
  Title,
  Message,
  ContactButton,
  ErrorMessageDiv
} from './styles';

import ModalFaleConosco from '../modal/faleConosco';

const ErrorLogin = ({ user, configuracao }) => {
  const [openFaleConosco, setOpenFaleConosco] = useState(false);

  const handleFaleConoscoClick = () => {
    setOpenFaleConosco(!openFaleConosco);
  };

  const hoje = new Date();
  const inicio = configuracao?.login_message_start_date
    ? new Date(configuracao.login_message_start_date)
    : null;
  const fim = configuracao?.login_message_end_date
    ? new Date(configuracao.login_message_end_date)
    : null;

  const dentroDoIntervalo =
    inicio && fim && hoje >= inicio && hoje <= fim;

  return (
    <Container>
      <InnerContainer>
        <Content>
          <ImageWrapper>
            <img src={AlertIcon} alt="Alert Icon" />
          </ImageWrapper>

          {configuracao?.personalized_login_error_message && dentroDoIntervalo ? (
            <ErrorMessageDiv
              dangerouslySetInnerHTML={{
                __html: configuracao.personalized_login_error_message
              }}
            />
          ) : (
            <>
              <Title>Desculpe, estamos passando por instabilidade</Title>
              <Message>
                Estamos fazendo melhorias por aqui. Por favor, tente novamente mais tarde, ou entre em contato conosco.
              </Message>
            </>
          )}
        </Content>

        <ButtonWrapper>
          <ContactButton onClick={handleFaleConoscoClick}>
            Fale conosco
          </ContactButton>
        </ButtonWrapper>
      </InnerContainer>

      <ModalFaleConosco
        isOpen={openFaleConosco}
        handleClose={() => setOpenFaleConosco(false)}
        id_usuario={user?.user_id}
        acao={configuracao?.name}
        link_acao={configuracao?.key}
        login={user?.user_login}
        cor={configuracao?.background}
        label_login={configuracao?.login_label}
        lgpd_cpf_truncado={configuracao?.login_type_cpf_truncated}
        action_id={configuracao?.id}
      />
    </Container>
  );
};

export default ErrorLogin;
